import styled from 'styled-components'
import { useEffect } from 'react'
import Link from 'next/link'
import Wrapper from 'common/wrapper'
import useAnimateNumber from 'use-animate-number'
import { Typography } from 'common/typography'
import useNearScreen from 'hooks/use-near-screen'
import { useTotalUsers } from 'querys/growth'
import UsersCarousel from './users-carousel'
import { PinkGradient } from './platform'

const TestimoniesStyled = styled.section`
  margin-block-start: 64px;
  overflow-x: hidden;
  h2 {
    ${PinkGradient}
    text-align: center;
    font-weight: 700;
    max-width: 1000px;
    margin: 0 auto;
  }
  .carousel {
    margin-block-start: 24px;
    margin-block-end: 250px;
  }
  .more {
    display: block;
    width: fit-content;
    margin: 0 auto;
    color: var(--turquoise);
    text-decoration: underline;
    margin-block-start: 48px;
    font-weight: 600;
  }
  .number {
    min-width: 90px;
  }
  @media (min-width: 768px) {
    .number {
      min-width: 212px;
    }
    .carousel {
      margin-block-start: 48px;
      margin-block-end: 220px;
    }
  }
`

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function Testimonies() {
  const { data } = useTotalUsers()
  const initalRandom = data?.count - randomIntFromInterval(10, 30)
  const [value, setValue] = useAnimateNumber(
    initalRandom,
    {
      duration: 3000,
      decimals: 0,
      enterance: false,
    },
  )
  const [show, element] = useNearScreen()

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setValue(data.count)
      }, 1500)
    }
  }, [show])

  return (
    <TestimoniesStyled aria-label="Testimonios de nuestros estudiantes">
      <Wrapper>
        <Typography small="headline5" large="headline2" as="h2">
          Más de
          <span className="number">
            {' '}
            {value}
            {' '}
          </span>
          frontends confían en nuestro plan de estudios
        </Typography>
      </Wrapper>
      <div className="carousel" ref={element}>
        {
          show && (
            <UsersCarousel />
          )
        }
        <Link href="/opiniones" className="more">
          Ver más opiniones de estudiantes
        </Link>
      </div>
    </TestimoniesStyled>
  )
}

export default Testimonies
