export const list1 = [
  {
    name: 'Ivan Bustos',
    url: '/images/testimonies/ivan_bustos.png',
    comment: 'En general buenos cursos, aprendí las bases necesarias de HTML y CSS. ',
  },
  {
    name: 'Katherin Suarz',
    url: '/images/testimonies/katherin_suarez_2.png',
    comment: 'Desde que hice el curso he aprendido y practicado tantas cosas de programación (Alguien me había dicho que esto era difícil) y ahora #Noterindas'
  },
  {
    name: 'Luis Abdel Flores',
    url: '/images/testimonies/luis_abdel_flores.png',
    comment: 'Ha sido súper satisfactorio el cursar y estudiar en la plataforma, realmente encantado con todo el trabajo que realiza ese gran equipo ',
  },
  {
    name: 'Paola Jazmin Salvador',
    url: '/images/testimonies/paola_jazmin_salvador.png',
    comment: 'La calidad de sus cursos y su pasión por enseñar de Leonidad, hace que aprender código sea un viaje interesante y emocionante.'
  },
  {
    name: 'Juan Carlos Valerio',
    url: '/images/testimonies/juan_carlos_valerio.png',
    comment: 'La calidad de los cursos se ve reflejada con la calidad y atención al detalle que tiene Leonidas en todos sus cursos y como instructor.',
  },
  {
    name: 'Rosa Trejo',
    url: '/images/testimonies/rosa_trejo.png',
    comment: ' ¿Quieres aprender practicando? Pues estás dónde debes. Marcan la diferencia con ""proyectos"" y su comunidad de Discord. Sin egos y con empatía.'
  },
  {
    name: 'Juan Esteban Londoño',
    url: '/images/testimonies/juan_esteban_londono.png',
    comment: 'Eres un gran profesor, me ayudaste a comprender las bases de HTML y CSS. Tu lema es el mejor #NoTeRindas ',
  },
  {
    name: 'Rosa Mora',
    url: '/images/testimonies/rosa_mora.png',
    comment: 'Ha sido increíble, va un paso más allá de sólo un curso. Leonidas y su equipo te motivan a crecer y ser mejor y eso es único.'
  },
  {
    name: 'Jose Chavez',
    url: '/images/testimonies/jose_chavez.png',
    comment: 'Los cursos de Leonidas son sinónimo de calidad y excelente contenido. Me han ayudado mucho a mejorar mis skills como programador . No te rindas!',
  },
  {
    name: 'Franciree Arellan',
    url: '/images/testimonies/franciree_arellan.png',
    comment: 'La paciencia y el cariño con que explica Leo me mantiene motivada a seguir aprendiendo y avanzando. Esos detalles hacen la diferencia.'
  },
  {
    name: 'Erick Alvarez',
    url: '/images/testimonies/erik_alvarez.png',
    comment: 'Me encanta la manera de explicar de leonidas, le he tomado un gusto enorme a javascript, me enseñó muchos trucos y maneras de resolver problemas'
  },
  {
    name: 'Dafne Villarroel',
    url: '/images/testimonies/dafne_villarroel.png',
    comment: 'La metodología, la empatía, y tips extra hacen que los cursos sean un diez. No te enseña como ser solo una programadora, sino una buena'
  },
  {
    name: 'Juan Nahuat',
    url: '/images/testimonies/juan_nahuat.png',
    comment: 'Desde que conocí a Leonidas me agradó su manera de enseñar, y la plataforma le quedo super bien y sus trucazos son muy útiles'
  },
  {
    name: 'Diana Ñañez',
    url: '/images/testimonies/diana_ñañez.png',
    comment: 'Muy buena metodología al brindarte teoría y los retos que nos dejan harán que mejores tus técnicas al codificar.✨'
  },
  {
    name: 'Daniel Suarez',
    url: '/images/testimonies/daniel_suarez.png',
    comment: 'Leónidas el grande sus cursos me llevan a otro nivel 🔥 gracias.'
  },
  {
    name: 'Codeka Code',
    url: '/images/testimonies/codeka_code.png',
    comment: 'Tuve la excelente oportunidad de usar los proyectos de la plataforma para aprender y mejorar mis habilidades en CSS y HTML. Recomendadisimo!.'
  },
  {
    name: 'Andres Chapeton',
    url: '/images/testimonies/andres_chapeton.png',
    comment: 'El curso te motiva ha aplicar cada concepto en tus proyectos y no solo ha terminarlo'
  },
  {
    name: 'Valentina Vargas Gutierrez',
    url: '/images/testimonies/valentina_vargas.png',
    comment: 'Los cursos son de verdad inspiradores, a demás de que aprendemos cosas nuevas en cada clase lo más importante es no rendirse y seguir adelante.'
  },
  {
    name: 'Andres Cuber',
    url: '/images/testimonies/andres_cuber.png',
    comment: 'Es súper buena la experiencia de tomar un curso en la plataforma, la calidad de la producción y la calidad de los cursos son excelentes.'
  },
  {
    name: 'Luciana Virginia Nuñez',
    url: '/images/testimonies/luciana_virginia.png',
    comment: 'El curso está genial e interesante, y me encanta cómo explica Leonidas. Sin dudas valen la pena los cursos, recomiendo al 100%.'
  },
  {
    name: 'Juan Pablo Solana',
    url: '/images/testimonies/juan_pablo_solana.png',
    comment: 'Después de tomar el curso elemental de HTML y CSS pude entender el funcionamiento del diseño y gracias a proyectos lo practico todos los días.'
  },
  {
    name: 'Marisol Carrillo Morales',
    url: '/images/testimonies/marisol_carrillo.png',
    comment: 'Me está pareciendo de muy buena calidad, tanto la producción como el contenido, es una excelente plataforma para los que van comenzando.'
  },
  {
    name: 'Raul Santana Cruz',
    url: '/images/testimonies/raul_santa_cruz.png',
    comment: 'La verdad me emociono cada vez que sale un curso nuevo! Siempre aprendes cosas nuevas y sobre todo los fundamentos que muchas veces desconocemos'
  },
]

export const list2 = [
  {
    name: 'Yadu Lopez',
    url: '/images/testimonies/yadu_lopez.png',
    comment: 'Proyectos ha sido muy útil para poder mejorar mi portafolio en Github y hace ver mucho más profesional mi perfil.'
  },
  {
    name: 'Cesar Choquehuanca',
    url: '/images/testimonies/cesar_choquehuanca.png',
    comment: 'Estoy llevando el curso de HTML y CSS junto con el de Figma, me parece sinceramente increible, aprendo nuevas cosas, buenas practicas. Super'
  },
  {
    name: 'Linda Reyes Tovar',
    url: '/images/testimonies/linda_reyes.png',
    comment: 'Leonidas no se rinde y va a encontrar todos los recursos para que entiendas todo de la manera más humana.'
  },
  {
    name: 'Manuel Puchades Breso',
    url: '/images/testimonies/daniel_puchades.png',
    comment: 'Estoy encantado con la plataforma. Va rapidísima y se nota que los cursos están hechos con mucha calidad y cariño.'
  },
  {
    name: 'Diana Lorena Herrera',
    url: '/images/testimonies/diana_lorena_herrera.png',
    comment: 'Estudiar en esta plataforma ha sido una bendición, me encanta el tono motivador que siempre tienen Leonidas.'
  },
  {
    name: 'Alexis',
    url: '/images/testimonies/alexis.png',
    comment: 'Seria gran ayuda tener un modo oscuro para los que estudiamos en la noche. Los fondos de los videos que no sean tan clarons también.'
  },
  {
    name: 'Lenrmatterano',
    url: '/images/testimonies/lenrmatterano.png',
    comment: 'Estupendo,me encanto la forma en qué explicó la técnica del Wrapper.Pude reforzar conocimientos d HTML/CSS.Excelente profesor lo recomiendo100%.'
  },
  {
    name: 'Adrian Bautista',
    url: '/images/testimonies/adrian_bautista.png',
    comment: 'Muy recomendado, dominio del tema, claridad en la explicación de los temas, te motiva a seguir aprendiendo.'
  },
  {
    name: 'Erika Dayanna Espejo',
    url: '/images/testimonies/erika_dayanna_espejo.png',
    comment: 'Con Leo aprendí las bases de lo que solía hacer de manera errónea. Lo que me permitió refactorizar mis procesos y aplicar buenas prácticas.'
  },
  {
    name: 'Manuel Saavedra',
    url: '/images/testimonies/manuel_saavedra.png',
    comment: 'Estudiar en Leonidasesteban.com ha sido genial gracias a ello estoy aprendiendo a usar figma.'
  },
  {
    name: 'A. Elízabeth',
    url: '/images/testimonies/a-elizabeth.png',
    comment: 'Súper recomendado, es una plataforma fácil dinámica y muy agradable de usar, lo mejor es que aprendes a tu ritmo y no hay presiones. '
  },
  {
    name: 'Octavio Chavez Olan',
    url: '/images/testimonies/octavio_alvarez.png',
    comment: 'El profesor trasmite entusiasmo a los estudiantes, pero lo más importante es movilizar los aprendizajes a través de un proyecto real.'
  },
  {
    name: 'Ana Citlalli Del Moral',
    url: '/images/testimonies/ana_citlalli_del_moral.png',
    comment: 'Mi parte favorita es la metodología utilizada en las clases, ya que te permite  internalizar el conocimiento y aplicarlo cuando lo necesites.'
  },
  {
    name: 'Jose Corzo',
    url: '/images/testimonies/jose_corzo.png',
    comment: 'Una de las mejores plataformas para empezar un curso, sin duda alguna es este,si quieres aprender o reforzar tus conocimientos esta es.'
  },
  {
    name: 'Claudia Dulcey',
    url: '/images/testimonies/claudia_dulcey.png',
    comment: 'Uno de los mejores cursos que he tomado! con seguridad aprendes algo nuevo.'
  },
  {
    name: 'Jesus Rosas',
    url: '/images/testimonies/jesus_rosas.png',
    comment: 'El curso de JS me pareció bastante completo y focalizado a la práctica.'
  },
  {
    name: 'Melissa Camacho Marín',
    url: '/images/testimonies/melissa_camacho.png',
    comment: 'Leonidas explica de forma precisa, clara y detallada, indicando además variantes, recursos y tips increíbles. ¡Es una gran plataforma!.',
  },
  {
    name: 'Felipe Merchan',
    url: '/images/testimonies/felipe_merchan.png',
    comment: 'Es una experiencia de aprendizaje muy enriquecedora. Llevo 8 años desarrollándome como profesional y nunca había aprendido tanto y tan fácil.'
  },
  {
    name: 'Katherin Suarez',
    url: '/images/testimonies/katherin_suarez.png',
    comment: 'Alguien me dijo Solo inteligentes pueden estudiar programación y acá estoy haciendo todos los cursos de Frontend #NOTERINDAS',
  },
  {
    name: 'Fernando Sanchez',
    url: '/images/testimonies/fernando_sanchez.png',
    comment: 'Excelente curso de JS, te da mucho a enter de como funciona React y que cuando decides aprender React ya se te hace más fácil. Excelente 100%.'
  },
  {
    name: 'Diana Rodriguez',
    url: '/images/testimonies/diana_rodriguez.png',
    comment: 'La plataforma tiene una excelente experiencia de usuario, es sencilla, funcional y práctica, además me encanta su navegación, no hay pierde.'
  },
  {
    name: 'Yuri Freitez',
    url: '/images/testimonies/yuri_freitez.png',
    comment: 'Son una plataforma de aprendizaje muy genial y con contenido de valor que ayuda tanto al que esta desde 0 como al que lleva un monton.'
  },
]
