import { useState } from 'react'
import styled from 'styled-components'
import Divider from 'common/divider'
import TestimonieList from './testimonie-list'
import { list1, list2 } from './testimonies-data'

const UsersCarouselStyled = styled.div`
  display: grid;
  &:hover article {
    animation-play-state: paused;
  }
`

function UsersCarousel() {
  const [active, setActive] = useState('')
  return (
    <UsersCarouselStyled>
      <TestimonieList list={list1} active={active} setActive={setActive} />
      <Divider marginVertical={0} marginBottom={32} color="transparent"  />
      <TestimonieList list={list2} active={active} setActive={setActive} />
    </UsersCarouselStyled>
  )
}

export default UsersCarousel
