import { useEffect, useState, useRef } from 'react'

const useNearScreen = (options) => {
  const element = useRef(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    Promise.resolve(
      typeof window.IntersectionObserver !== 'undefined'
        ? window.IntersectionObserver
        : import('intersection-observer'),
    ).then(() => {
      if (element.current) {
        const observerOptions = options || {}
        const observer = new window.IntersectionObserver((entries) => {
          const { isIntersecting } = entries[0]
          if (isIntersecting) {
            setShow(true)
            observer.disconnect()
          }
        }, observerOptions)
        observer.observe(element.current, observerOptions)
      }
    })
  }, [element.current])

  return [show, element]
}

export default useNearScreen
