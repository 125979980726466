import { useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { H4, P } from 'common/typography'

const TestimonieListStyled = styled.article`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 32px;
  grid-auto-columns: 200px;
  will-change: transform;
  :first-child {
    z-index: 2;
    animation: toLeft 270s infinite alternate steps(4500);
  }
  :last-child {
    transform: translateX(-83%);
    animation: toRight 270s infinite alternate steps(4500);
  }
  .avatar {
    border-radius: 8px;
    object-fit: cover;
  }
  @keyframes toLeft {
    from {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-20%);
    }
    50% {
      transform: translateX(-40%);
    }
    75% {
      transform: translateX(-60%);
    }
    to {
      transform: translateX(-80%);
    }
  }
  @keyframes toRight {
    from {
      transform: translateX(-80%);
    }
    25% {
      transform: translateX(-60%);
    }
    50% {
      transform: translateX(-40%);
    }
    75% {
      transform: translateX(-20%);
    }
    to {
      transform: translateX(0);
    }
  }
`

const TestimoniesStyled = styled.li`
  list-style: none;
  transition: 0.2s ease-in opacity;
  opacity: ${({ active, name }) => (active && active !== name ? '0.2' : '1')};
  z-index: ${({ active, name }) => (active && active !== name ? '1' : '-1')};
  opacity: ${({ active }) => (active === '' && '1')};
  z-index: ${({ active }) => (active === '' && '1')};
  position: relative;
  .testimonie {
    position: absolute;
    z-index: 3;
    top: 168px;
    left: 75px;
    display: block;
    visibility: ${({ active, name }) => (active && active !== name ? 'hidden' : 'visible')};
    opacity: ${({ active, name }) => (active && active !== name ? '0' : '1')};
    visibility: ${({ active }) => (active === '' && 'hidden')};
    opacity: ${({ active }) => (active === '' && '0')};
    border-radius: 16px;
    backdrop-filter: blur(90.9968px);
    padding: 4px;
    background-image: linear-gradient(147.52deg, #874053 23.89%, #9499A6 67.19%);
  }
  .testimonie-container {
    width: 312px;
    border-radius: 16px;
    padding: 64px 24px 24px;
    background: linear-gradient(0deg, rgba(41, 21, 68, 0.5), rgba(41, 21, 68, 0.5)), linear-gradient(123.82deg, #AC4F68 -13.64%, #0E0230 87.27%);
  }
  @media(min-width: 768px) {
    .testimonie-container {
      border-radius: 16px;
      width: 400px;
      padding: 64px 48px 48px;
    }
  }
`

function Testimonie({
  name, url, comment, setActive, active,
}) {
  const element = useRef(null)
  const handleMouseEnter = (e) => {
    const testmonie = e.currentTarget.querySelector('.testimonie')
    const testmoniePosition = testmonie.getBoundingClientRect()
    const finalPoint = testmoniePosition.x + testmoniePosition.width
    if (finalPoint > window.innerWidth - 20) {
      const testimonieStart = window.innerWidth - testmoniePosition.width - 20
      const left = testmoniePosition.x - 75 - testimonieStart
      testmonie.style.left = `-${left}px`
    } else {
      testmonie.style.left = '75px'
    }
    setActive(name)
  }
  const handleMouseLeave = (e) => {
    const testmonie = e.currentTarget.querySelector('.testimonie')
    testmonie.style.left = '75px'
    setActive('')
  }
  return (
    <TestimoniesStyled
      active={active}
      name={name}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={element}
    >
      <img
        key={url}
        src={url}
        height={200}
        width={200}
        alt={name}
        className="avatar"
        loading="lazy"
      />
      <div className="testimonie">
        <div className="testimonie-container">
          <H4 fontSize="20" lineHeight="32" color="var(--white)">{name}</H4>
          <P fontSize="16" lineHeight="24" color="var(--white)">{comment}</P>
        </div>
      </div>
    </TestimoniesStyled>
  )
}

function TestimonieList({ list, active, setActive }) {
  return (
    <TestimonieListStyled active={active} className="testimonie-list">
      {list.map((testimonie) => (
        <Testimonie key={`${testimonie.name}-1`} {...testimonie} active={active} setActive={setActive} />
      ))}
      {list.map((testimonie) => (
        <Testimonie key={`${testimonie.name}-2`} {...testimonie} active={active} setActive={setActive} />
      ))}
    </TestimonieListStyled>
  )
}

TestimonieList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    comment: PropTypes.string,
  })).isRequired,
  setActive: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
}

export default TestimonieList
